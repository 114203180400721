.opacity45 {
	opacity: 45%;
}

.borderBottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.dropdownContainer {
	box-shadow: 0px 2px 5px 0 rgb(0 0 0 / 25%);
	border-radius: 6px;
	width: 360px;
	margin-top: 5px;
	position: absolute;
	z-index: 1000;
	background-color: var(--color-gray-ligther);
	overflow: hidden;
	background-color: white;
}

.dropdownContainer h6 {
	font-size: 0.75rem;
	color: var(--color-gray-blue);
	text-transform: capitalize;
	margin: 0;
}

.dropdownContainer div div {
	padding: 15px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropdowbCategoryRowOpen {
	background-color: var(--color-gray-blue);
	@extend .dropdownContainer border-radius: inherit inherit;
}

.dropdowbCategoryRowOpen h6 {
	color: white;

}

.dropdowbCategoryRowOpen>h2 {
	color: white;
}

.dropdownContainer section {
	flex-direction: column;
}

.dropdownContainer section div {
	padding: 15px 20px;
	font-size: 0.75rem;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.dropdownContainer a,
.pcDropdownMenu a {
	text-decoration: none;
	text-transform: capitalize;
	color: var(--color-gray-dark);
}

.navbar-container-options {
	display: flex;
	flex-direction: row;
	min-height: inherit;
}

.pcDropdownMenu {
	display: none;
	flex-direction: row;
	cursor: default;
}

.pcDropdownMenu div {
	text-align: left;
	width: 100px;
	margin-right: 50px;
}

.pcDropdownMenu h6 {
	font-size: 0.7rem;
	font-weight: 500;
	color: var(--color-gray-blue);
	text-transform: capitalize;
	margin-top: 10px;
	margin-bottom: 0;
}

.pcDropdownMenu hr {
	opacity: 1;
	height: 2px;
	margin: 5px 0 15px;
	background-color: var(--color-gray-blue);
}

.pcDropdownMenu h4 {
	font-size: 0.7rem;
	font-weight: 300;
	color: #000;
	margin-top: 15px;
}

.pcDropdownMenu h4:hover {
	font-weight: 500;
	transform: scale(1.2) translateX(10px);
	transition: transform 0.25s;
	color: var(--color-gray-blue);
}

.navbar {
	background-color: var(--color-gray-blue-dark);
	padding: 0 !important;
	min-height: 51px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.navbar-rigth-colum {
	display: flex;
	flex-direction: row;
	padding-right: 15px;
	justify-content: flex-end;
	gap: 20px;
	width: 85%;
}

.navbar-rigth-colum button {
	position: absolute;
	border: none;
	border-left: 1px solid var(--color-gray-blue-dark);
	border-radius: 0 30px 30px 0;
	background-color: white;

	color: var(--color-gray-blue-dark);
	font-size: 16px;
	font-weight: 500;
	height: 35px;
	top: 8px;
	padding: 0 15px;
}

.navbar-rigth-colum button:hover {
	background-color: var(--color-gray-blue);
	color: white;
	border: 1px solid white;
}

.navbar-acsesos-rapidos {
	display: none;
	min-height: inherit;
	cursor: pointer;
}

.navbar-home-a {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: white;
	text-decoration: none;
	align-items: center;
}

.navbar-home-a:hover {
	color: white;
	text-decoration: none;
}

.navbar-home-a>li>div {
	padding: 0;
}

.menuItems-container ul {
	display: flex;
	justify-content: space-around;
	padding: 0;
	height: 100%;
	width: 100%;
	margin-bottom: 0;
	min-height: inherit;
}

.menuItems-container ul div {
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.menuItems-container ul li>* {
	display: flex;
	flex-direction: column;
}

.navbar-accesos-rapidos-img,
.menuItems-container ul div img:nth-child(1) {
	width: 35px;
	height: 35px;
	padding-left: 10px;
}

.menuItems-container ul div img:nth-child(1) {
	display: none;
}

.menuItems-container ul div img:nth-child(2) {
	height: 50px;
	width: 50px;
	border: 1px solid var(--color-gray-blue);
	padding: 7px;
	border-radius: 25%;
}

.menuItems-container ul div img:nth-child(2):hover {
	background-color: var(--color-gray-blue-dark) !important;
}

.menuItems-container ul h4 {
	display: none;
	font-size: 0.8rem;
	margin: 0;
	text-transform: capitalize;
}

.menuItems-container ul li {
	list-style: none;
	display: flex;
	align-items: center;
}

.navbar-barra-busqued {
	margin: auto 0;
}

.navbar-barra-busqued .m_8fb7ebe7:focus,
.navbar-barra-busqued .m_8fb7ebe7:focus-within {
	--input-bd: none;
}

.navbar-barra-busqued .m_6c018570[data-variant='default'] {
	--input-bd: none !important;
}

.navbar-barra-busqued,
.navbar-barra-busqued>div,
.navbar-barra-busqued>div>div>div {
	width: 100%;
	min-height: 36px;
}

.menuItems-container {
	margin: 5px 0;
	display: block;
	height: 100%;
}


@media (min-width: 744px) and (max-width: 1298px) {
	.dropdownContainer {
		width: 744px;
	}

	.navbar {
		min-height: 60px !important;
	}

	.navbar-home-a {
		padding: 0 16px;
	}

	.navbar-barra-busqued,
	.navbar-barra-busqued>div,
	.navbar-barra-busqued>div>div>div {
		width: 400px;
		min-height: 30px;
	}

	.navbar-rigth-colum {
		padding-right: 30px;
		justify-content: flex-end;
		gap: 20px;
	}


	.navbar-rigth-colum button {
		top: 12px;
		height: 36px;
	}

	.navbar-accesos-rapidos-img,
	.menuItems-container ul div img:nth-child(1) {
		padding-left: 0px;
	}


	.menuItems-container {
		margin: 10px 0;
	}
}

@media (min-width: 1298px) {
	.menuItems-container ul section {
		flex-direction: column;
		margin: auto 15px;
		justify-content: center;
		align-items: center;
	}

	.dropdownContainer {
		display: none;
	}

	.pcDropdownMenu {
		display: flex;
		width: 50%;
		min-width: 780px;
		position: absolute;
		left: 40px;
		top: 88px;
		padding: 10px 0 10px 40px;
		border-radius: 10px;
		box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.25);
		background-color: #fff;
	}

	.opacity45 {
		background-color: rgba(0, 0, 0, 0.14);
		opacity: 1;
	}

	.navbar {
		min-height: 88px !important;
		display: flex;
		padding: 0;
		justify-content: space-between;
	}

	.navbar-home-a {
		padding: 0 16px;
		margin-left: 20px;
	}

	.menuItems-container {
		margin: 0;
	}

	.navbar-acsesos-rapidos {
		display: flex;
		align-items: center;
		width: auto;
	}

	.menuItems-container ul h4 {
		display: block;
	}

	.menuItems-container ul div img:nth-child(2) {
		display: none;
	}

	.navbar-accesos-rapidos-img,
	.menuItems-container ul div img:nth-child(1) {
		width: 50px;
		height: 50px;
		object-fit: scale-down;
		padding-left: 0;
	}


	.navbar-accesos-rapidos-img {
		border: 1px solid white;
		border-radius: 10px;
		cursor: pointer;
	}

	.navbar-accesos-rapidos-img:hover {
		background-color: var(--color-gray-blue);
	}

	.menuItems-container ul div img:nth-child(1) {
		display: block;
	}

	.navbar-rigth-colum {
		padding-right: 30px;
		justify-content: flex-end;
		gap: 20px;
		width: auto;
	}

	.navbar-rigth-colum button {
		top: 19px;
		height: 44px;

	}

	.navbar-barra-busqued,
	.navbar-barra-busqued>div,
	.navbar-barra-busqued>div>div>div {
		width: 237px;
		min-height: 46px;
		width: 400px;
	}

	.navbar-barra-busqued {
		height: 51px;
		padding-top: 0;
	}
}