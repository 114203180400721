.navbarMenu-container {
    height: 100vh;
    width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-right: 2px solid var(--color-gray-blue-ligth);
    background-color: var(--color-gray-blue-dark);

    overflow: auto;
    overflow-x: hidden;
}

.navbarMenu-container img {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.navbarMenu-navbarMain {
    flex: 1;
}

.navbarMenu-header {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--color-gray-blue-ligth);

    display: flex;
    align-items: center;
}

.navbarMenu-header img {
    rotate: 180deg;
    width: 15px;
    cursor: pointer;
}

.navbarMenu-header h6 {
    color: white;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
}

.navbarMenu-footer {
    padding: 10px 0 20px 0;
    margin-top: auto;
    border-top: 2px solid var(--color-gray-blue-ligth);
}

.navbarMenu-container section {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
}

.navbarMenu-container section h4 {
    color: white;
    margin: 0;
    font-size: 12px;
}

.navbarMenu-container section span {
    background-color: white;
    border-radius: 7px;
    color: var(--color-gray-blue-dark);
    font-weight: 700;
    font-size: 12px;
    padding: 1px 5px;
}

.navbarMenu-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;
    color: var(--color-gray-ligther);
    padding: 10px;
    border-radius: 10px;
    font-weight: 500;
    margin-bottom: 5px;
}

.navbarMenu-link:hover {
    cursor: pointer;
    background-color: var(--color-gray-dark);
    color: white;
}

.navbarMenu-link:focus {
    cursor: pointer;
    background-color: var(--color-gray-blue-ligther);
    color: white;
}

.navbarMenu-linkIcon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.navbarMenu-container *[data-active] {
    background-color: var(--color-gray-blue);
    color: white;
}