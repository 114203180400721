.recommendation-container {
    margin-bottom: 15px;
}

.recommendation-container>*,
.recommendation-container .mantine-Accordion-item {
    background-color: white;
    border-color: var(--color-gray-ligth);
}

.recommendation-container .mantine-Switch-label,
.recommendation-container .mantine-Accordion-label {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    color: var(--color-gray);
}

.recommendation-container .mantine-Accordion-label {
    display: flex;
    gap: 13px;
}